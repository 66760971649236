<template>
	<v-row>
		<v-col cols="12" v-if="roleName === 'SYSTEM_ADMIN'">
			<app-bar-filter-system-admin />
		</v-col>
		<v-col cols="12" v-else-if="roleName === 'ORGANIZATION_ADMIN'">
			<app-bar-filters-organization-admin />
		</v-col>
    <v-col cols="12" v-else-if="roleName === 'PARTNER'">
      <app-bar-filter-partners />
    </v-col>
    <v-col cols="12" v-else-if="roleName === 'HOSPITAL_ADMIN' || roleName === 'DOCTOR' || roleName === 'NURSE'">
      <app-bar-filter-hospital-admin/>
    </v-col>
<!--		<v-col cols="12" v-else>-->
<!--			<app-bar-filter-hospital-admin/>-->
<!--		</v-col>-->
	</v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import AppBarFilterHospitalAdmin from '../AppBarFilters/AppBarFiltersHospitalAdmin.vue';
import AppBarFilterSystemAdmin from '../AppBarFilters/AppBarFiltersSystemAdmin.vue';
import AppBarFiltersOrganizationAdmin from '../AppBarFilters/AppBarFiltersOrganizationAdmin.vue';
import AppBarFilterPartners from '../AppBarFilters/AppBarFilterPartners.vue';

export default {
	name: 'DashboardCoreDrawer',
	components: { AppBarFilterPartners, AppBarFilterHospitalAdmin, AppBarFilterSystemAdmin, AppBarFiltersOrganizationAdmin },
	computed: {
		...mapGetters({
			roleName: 'authentication/getRole',
		}),
	},
};
</script>
