import { render, staticRenderFns } from "./AppBarFilterPartners.vue?vue&type=template&id=283deffc&scoped=true"
import script from "./AppBarFilterPartners.vue?vue&type=script&lang=js"
export * from "./AppBarFilterPartners.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "283deffc",
  null
  
)

export default component.exports