
<template>
    <v-card elevation="0">
      <v-row dense v-if="$route.path === '/partnerBillingReports'" class="px-3 py-1 align-center d-flex app-bar-borders" >
        <v-col cols="2">
          <v-text-field
              class="width-text-field1"
              prepend-inner-icon="mdi-magnify"
              hide-details
              dense
              v-model="search"
              :label="$t('search')"
              outlined
          ></v-text-field>
        </v-col>
<!--        <v-col class="text-right" cols="10">-->
<!--          <v-btn  @click="syncronizeDrawers" color="chips" variant="tonal" prepend-icon="mdi-plus">-->
<!--            <img class="me-3" height="15" :src="addWhite"/>-->
<!--            <span class="text-capitalize text-color-white">{{ $t(pathname) }}</span>-->
<!--          </v-btn>-->
<!--        </v-col>-->
      </v-row>
    </v-card>
</template>
<script>
export default {
  name: 'AppBarFilterPartners.vue',
  data () {
    return {
      search: null,
    };
  },
  watch: {
    async search (val) {
      await this.$store.dispatch('filterbar/AppBarSearch', val);
    },
  },
};
</script>

<style scoped>

</style>
